var render = function render(){var _vm=this,_c=_vm._self._c;return _c('k-page',[(!_vm.loading)?_c('k-form',{ref:"form"},[_c('v-container',[_c('SectionHeader',{attrs:{"title":"Dados da Obra"}}),_c('v-row',{staticClass:"my-4"},[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","rules":[_vm.$validation.isRequired],"error-messages":_vm.errors.name,"label":"Nome da Obra","hint":"Informe um nome descritivo para esta obra","persistent-hint":"","required":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1)],1),_c('SectionHeader',{attrs:{"title":"Cliente Contratante","subtitle":"Selecione a pessoa contratante desta obra","actions":[
          {
            to: `/people/add`,
            target: '_blank',
            icon: 'mdi-plus',
            label: 'Cadastrar nova pessoa',
          },
        ]}}),_c('v-row',{staticClass:"my-4"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('PersonInfoSelect',{attrs:{"label":"Contratante","dense":"","outlined":"","person":_vm.form.contractor,"error-messages":_vm.errors.contractor_id,"search-params":{ showRoles: '' }},on:{"select":_vm.onSelectContractor,"clear":function($event){_vm.form.contractor = {}}},model:{value:(_vm.form.contractor_id),callback:function ($$v) {_vm.$set(_vm.form, "contractor_id", $$v)},expression:"form.contractor_id"}})],1)],1),_c('SectionHeader',{attrs:{"title":"Cliente Proprietário","subtitle":"Selecione a pessoa proprietária desta obra","actions":[
          {
            to: `/people/add`,
            target: '_blank',
            icon: 'mdi-plus',
            label: 'Cadastrar nova pessoa',
          },
        ]}}),_c('v-row',{staticClass:"my-4"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('PersonInfoSelect',{attrs:{"label":"Proprietário","dense":"","outlined":"","person":_vm.form.owner,"error-messages":_vm.errors.owner_id,"search-params":{ showAddresses: true, showRoles: '' }},on:{"select":_vm.onSelectOwner,"clear":_vm.onClearOwner},model:{value:(_vm.form.owner_id),callback:function ($$v) {_vm.$set(_vm.form, "owner_id", $$v)},expression:"form.owner_id"}})],1)],1),_c('SectionHeader',{attrs:{"title":"Endereço da Obra","subtitle":"Selecione um dos endereços vinculados ao proprietário da obra","actions":[
          {
            icon: 'mdi-refresh',
            eventName: 'click:refresh',
            label: 'Atualizar Endereços do Proprietário',
          },
        ]},on:{"click:refresh":_vm.refreshAddresses}}),(!_vm.form.owner_id)?_c('KAlert',{attrs:{"info":""}},[_vm._v(" Para selecionar um endereço é necessário informar o proprietário da obra ")]):_vm._e(),(_vm.form.owner_id)?_c('v-row',{staticClass:"my-4"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('KInputSelect',{attrs:{"dense":"","outlined":"","item-text":"description","item-subtext":"address","item-value":"id","no-data-text":"Nenhum endereço cadastrado para este proprietário","items":_vm.addressesFull,"error-messages":_vm.errors.address_id},model:{value:(_vm.form.address_id),callback:function ($$v) {_vm.$set(_vm.form, "address_id", $$v)},expression:"form.address_id"}})],1)],1):_vm._e(),(_vm.isEditing)?[_c('SectionHeader',{staticClass:"mt-3",attrs:{"title":"Serviços","subtitle":"Listagem de serviços realizados nesta obra","actions":[
            {
              to: `/constructions/${_vm.form.id}/services/add`,
              icon: 'mdi-plus',
              label: 'Cadastrar novo serviço',
            },
            {
              icon: 'mdi-refresh',
              eventName: 'click:refresh',
              label: 'Atualizar a lista de serviços',
            },
          ]},on:{"click:refresh":_vm.handleRefreshServiceClick}}),_c('ServicesList',{ref:"services-list",attrs:{"construction_id":_vm.form.id}}),_c('SectionHeader',{attrs:{"title":"Grupos da Obra","subtitle":"Agrupamentos de arquivos e informações relacionadas à obra","actions":[
            {
              to: '/grouping',
              icon: 'mdi-plus',
              label: 'Cadastrar novo grupo',
            },
            {
              icon: 'mdi-refresh',
              eventName: 'click:refresh',
              label: 'Atualizar a lista de grupos',
            },
          ]},on:{"click:refresh":_vm.handleRefreshGroupClick}}),_c('GroupsList',{ref:"groups-list",attrs:{"reference_module":"construction","reference_module_id":_vm.form.id}}),_c('SectionHeader',{staticClass:"mt-3",attrs:{"title":"Relatórios","subtitle":"Dados agrupados em formato de visualização"}}),_c('ReportButtons',{staticClass:"mb-6",attrs:{"public_key":_vm.form.public_key,"private_key":_vm.form.private_key}})]:_vm._e()],2),_c('v-row',{staticClass:"ma-0 mb-8",attrs:{"align":"center","justify":"center"}},[_c('v-btn',{attrs:{"color":"accent","elevation":"0","rounded":""},on:{"click":_vm.save}},[_vm._v("Salvar Alterações")])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }