<template>
  <k-page>
    <k-form ref="form" v-if="!loading">
      <v-container>
        <SectionHeader title="Dados da Obra" />

        <v-row class="my-4">
          <!-- Nome da Obra -->
          <v-col cols="12" class="py-1">
            <v-text-field
              dense
              outlined
              :rules="[$validation.isRequired]"
              :error-messages="errors.name"
              label="Nome da Obra"
              hint="Informe um nome descritivo para esta obra"
              persistent-hint
              v-model="form.name"
              required
            ></v-text-field>
          </v-col>
        </v-row>

        <SectionHeader
          title="Cliente Contratante"
          subtitle="Selecione a pessoa contratante desta obra"
          :actions="[
            {
              to: `/people/add`,
              target: '_blank',
              icon: 'mdi-plus',
              label: 'Cadastrar nova pessoa',
            },
          ]"
        />

        <v-row class="my-4">
          <!-- Contratante -->
          <v-col cols="12" class="py-0">
            <PersonInfoSelect
              label="Contratante"
              dense
              outlined
              :person="form.contractor"
              :error-messages="errors.contractor_id"
              :search-params="{ showRoles: '' }"
              v-model="form.contractor_id"
              @select="onSelectContractor"
              @clear="form.contractor = {}"
            />
          </v-col>
        </v-row>

        <SectionHeader
          title="Cliente Proprietário"
          subtitle="Selecione a pessoa proprietária desta obra"
          :actions="[
            {
              to: `/people/add`,
              target: '_blank',
              icon: 'mdi-plus',
              label: 'Cadastrar nova pessoa',
            },
          ]"
        />

        <v-row class="my-4">
          <!-- Proprietário -->
          <v-col cols="12" class="py-0">
            <PersonInfoSelect
              label="Proprietário"
              dense
              outlined
              :person="form.owner"
              :error-messages="errors.owner_id"
              :search-params="{ showAddresses: true, showRoles: '' }"
              v-model="form.owner_id"
              @select="onSelectOwner"
              @clear="onClearOwner"
            />
          </v-col>
        </v-row>

        <SectionHeader
          title="Endereço da Obra"
          subtitle="Selecione um dos endereços vinculados ao proprietário da obra"
          :actions="[
            {
              icon: 'mdi-refresh',
              eventName: 'click:refresh',
              label: 'Atualizar Endereços do Proprietário',
            },
          ]"
          @click:refresh="refreshAddresses"
        >
        </SectionHeader>

        <KAlert v-if="!form.owner_id" info>
          Para selecionar um endereço é necessário informar o proprietário da
          obra
        </KAlert>

        <v-row class="my-4" v-if="form.owner_id">
          <v-col cols="12" class="py-0">
            <KInputSelect
              dense
              outlined
              item-text="description"
              item-subtext="address"
              item-value="id"
              no-data-text="Nenhum endereço cadastrado para este proprietário"
              :items="addressesFull"
              :error-messages="errors.address_id"
              v-model="form.address_id"
            />
          </v-col>
        </v-row>

        <!-- 

              Edição

           -->
        <template v-if="isEditing">
          <!-- Serviços vinculados a esta obra -->
          <SectionHeader
            title="Serviços"
            subtitle="Listagem de serviços realizados nesta obra"
            class="mt-3"
            :actions="[
              {
                to: `/constructions/${form.id}/services/add`,
                icon: 'mdi-plus',
                label: 'Cadastrar novo serviço',
              },
              {
                icon: 'mdi-refresh',
                eventName: 'click:refresh',
                label: 'Atualizar a lista de serviços',
              },
            ]"
            @click:refresh="handleRefreshServiceClick"
          />

          <ServicesList :construction_id="form.id" ref="services-list" />

          <!-- Grupos -->
          <SectionHeader
            title="Grupos da Obra"
            subtitle="Agrupamentos de arquivos e informações relacionadas à obra"
            :actions="[
              {
                to: '/grouping',
                icon: 'mdi-plus',
                label: 'Cadastrar novo grupo',
              },
              {
                icon: 'mdi-refresh',
                eventName: 'click:refresh',
                label: 'Atualizar a lista de grupos',
              },
            ]"
            @click:refresh="handleRefreshGroupClick"
          />

          <GroupsList
            reference_module="construction"
            :reference_module_id="form.id"
            ref="groups-list"
          />

          <!-- Botões do QR-Code -->
          <SectionHeader
            class="mt-3"
            title="Relatórios"
            subtitle="Dados agrupados em formato de visualização"
          />

          <ReportButtons
            class="mb-6"
            :public_key="form.public_key"
            :private_key="form.private_key"
          />
        </template>
      </v-container>

      <!--  -->
      <v-row class="ma-0 mb-8" align="center" justify="center">
        <v-btn color="accent" elevation="0" rounded @click="save"
          >Salvar Alterações</v-btn
        >
      </v-row>
    </k-form>
  </k-page>
</template>

<script>
import KForm from "@/components/KForm";
import KPage from "@/components/KPage";
import KAlert from "@/components/KAlert";
import KInputSelect from "@/components/KInput/Select";
import SectionHeader from "@/components/SectionHeader";

import PersonInfoSelect from "@/components/PersonInfoSelect";
import ReportButtons from "./shared/ReportButtons";

import ServicesList from "./Services/List";
import GroupsList from "@/views/Grouping/Children/List";

import formatterAddress from "@/components/AddressFormatter/formatter";

export default {
  name: "page-save",

  components: {
    KForm,
    KPage,
    KAlert,
    KInputSelect,
    SectionHeader,
    PersonInfoSelect,
    ReportButtons,
    ServicesList,
    GroupsList
  },

  data() {
    return {
      form: {},
      errors: [],
      addresses: [],
      loading: false
    };
  },

  computed: {
    isEditing() {
      return !!this.$route.params.id;
    },

    addressesFull() {
      if (!this.addresses) return [];
      return this.addresses.map(item => ({
        ...item,
        address: formatterAddress(item)
      }));
    }
  },

  methods: {
    /**
     *
     *
     */
    async save() {
      try {
        this.$loading.show();
        this.errors = {};
        // console.log(this.form);
        const payload = {
          id: this.form.id,
          name: this.form.name,
          contractor_id: this.form.contractor_id,
          owner_id: this.form.owner_id,
          address_id: this.form.address_id
        };

        const construction = await this.$store.dispatch("constructions/save", {
          payload,
          editing: this.isEditing
        });

        this.$loading.hide();
        this.$message.success("Registro salvo com sucesso");
        if (!this.isEditing) {
          // console.log("é edita", construction);
          this.$router.push(`/constructions/view/${construction.id}`);
        }
      } catch (error) {
        this.$loading.hide();
        this.errors = this.$message.serverError(error);
        // console.log("erro ao salvar", errors);
        // console.log(error);
      }
    },

    /**
     *
     *
     */
    onSelectOwner(person) {
      const { addresses, ...owner } = person;
      // console.log("onSelectOwner", owner);

      // console.log(addresses);
      this.addresses = addresses;

      this.form.owner = owner;
      // this.form.owner_id = owner.id;
    },

    /**
     *
     *
     */
    onClearOwner() {
      this.form.address_id = null;
      this.form.owner = {};
    },

    /**
     *
     *
     */
    onSelectContractor(person) {
      const { addresses, ...contractor } = person;
      // console.log("onSelectContractor", contractor);

      this.form.contractor = contractor;
      // this.form.owner_id = owner.id;
    },

    /**
     *
     *
     */
    async load() {
      this.$loading.show();
      this.loading = true;

      const { data } = await this.$api.get(
        `/constructions/${this.$route.params.id}`
      );

      // console.log(data.data);
      this.form = data.data;
      // this.addresses = data.data.owner.addresses;
      this.onSelectOwner(data.data.owner);

      //
      this.$app.title(`Obra ${data.data.name}`);

      this.$loading.hide();
      this.loading = false;
    },

    /**
     *
     */
    async loadAddressByPerson(personId) {
      try {
        const { data } = await this.$api.get(`/people/${personId}/addresses`);
        this.addresses = data.data;
      } catch (error) {
        this.$message.error("Erro ao carregar os endereços deste proprietário");
      }
    },

    refreshAddresses() {
      this.loadAddressByPerson(this.form.owner_id);
    },

    handleRefreshGroupClick() {
      this.$refs["groups-list"].loadItems();
    },

    handleRefreshServiceClick() {
      this.$refs["services-list"].load();
    }
  },

  async mounted() {
    if (this.isEditing) {
      await this.load();
      this.loadAddressByPerson(this.form.owner_id);
    }
  }
};
</script>

<style>
</style>
