<template>
  <KPage fluid>
    <!-- Botões -->
    <v-row class="mx-0 my-3 flex justify-center">
      
      <v-col cols="12">
        <SectionHeader title="Serviços" />
      </v-col>
      <v-col
        class="pa-0 mb-4"
        cols="6"
        md="4"
        xl="2"
        v-for="item in statsList"
        :key="item.title"
      >
        <CardInfo v-bind="item"  />
      </v-col>
    </v-row>

    <v-row class="mx-0 my-3">
      <v-col cols="12">
        <SectionHeader title="Obras" />
      </v-col>
    </v-row>

    <!-- Barra de busca -->
    <FilterBar :page="page" />

    <!--  -->
    <v-card outlined class="overflow-hidden">
      <v-row class="ma-0">
        <v-col cols="12" xs="12" class="pa-0">
          <v-list class="py-0 bordered">
            <!--  -->
            <v-list-item
              v-for="construction in items"
              :key="`item-${construction.id}`"
              :to="`/constructions/view/${construction.id}`"
            >
              <v-list-item-content>
                <v-list-item-title>{{ construction.name }}</v-list-item-title>
                <v-list-item-subtitle class="text-wrap">
                  <KChip x-small blue class="mr-1 mb-1"
                    >Proprietário: {{ construction.owner.name }}</KChip
                  >
                  <KChip x-small orange class="mr-1 mb-1"
                    >Contratante: {{ construction.contractor.name }}</KChip
                  >
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon>mdi-chevron-right</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-card>

    <!-- Barra de paginação -->
    <pagination-bar :pagination="pagination" v-model="page" />

    <!-- Separa o rodapé do botão de cadastrar -->
    <v-row style="height: 38px"></v-row>

    <!-- Botão FAB -->
    <FAB :visible="!loading" @click="add"></FAB>
  </KPage>
</template>

<script>
import FAB from "@/components/FAB";
import KPage from "@/components/KPage";
import KChip from "@/components/KChip";
import SectionHeader from "@/components/SectionHeader";
import PaginationBar from "@/components/Pagination/Bar";

import FilterBar from "./FilterBar";

import CardInfo from "@/components/CardInfo";

export default {
  components: {
    FAB,
    KPage,
    KChip,
    SectionHeader,
    PaginationBar,

    FilterBar,

    CardInfo
  },

  data() {
    return {
      //
      loading: false,

      //
      dialog: {
        visible: true
      },

      // Dados dos filtros
      query: {},

      page: 1
    };
  },
  watch: {
    pagination(val, old) {
      if (old.page != val.page) this.page = val.page;
    }
  },
  computed: {
    pagination() {
      return this.$store.getters["constructions/pagination"];
    },
    items() {
      return this.$store.getters["constructions/all"];
    },
    statsList() {
      return this.$store.getters["constructions/stats"]
        .filter(item => item.value)
        .map(item => {
          let to = "/constructions/services";
          const query = {};
          if (item.slug) query.status = item.slug;
          if (item.next_days) query.next_days = 30;

          to += "?" + new URLSearchParams(query);

          return {
            ...item,
            to
          };
        });
    }
  },

  //
  methods: {
    add() {
      this.$router.push("/constructions/add");
    },
    /**
     * Carrega os dados pela primeira vez
     */
    async loadData() {
      try {
        this.loading = true;
        this.$loading.show();

        await Promise.all([this.$store.dispatch("constructions/all")]);

        this.$loading.hide();
        this.loading = false;
      } catch (error) {
        this.$message.serverError(error);
        this.$loading.hide();
        this.loading = false;
      }
    }
  },

  mounted() {
    this.loadData();
  }
};
</script>

