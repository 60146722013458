<template>
  <v-toolbar flat class="pl-2">
    <v-btn icon @click="refresh">
      <v-icon>mdi-refresh</v-icon>
    </v-btn>

    <!--  -->
    <!-- Filtro por tipo -->
    <!--
      <v-col class="pa-0">
       <v-select
        class="mr-1"
        label="Filtrar por Carreira"
        solo
        v-model="query.category"
        prepend-inner-icon="mdi-filter-variant"
        hide-details
        flat
        clearable
        @change="filter"
        item-value="value"
        item-text="name"
        item-disabled="disabled"
        :items="categories"
      ></v-select> 
    </v-col>-->

    <!--  -->
    <v-col class="pa-0">
      <v-text-field
        class="mr-1"
        @keyup.enter.stop.prevent="filter"
        @click:clear="onClear"
        v-model="query.q"
        clearable
        label="Pesquisar por Nome da obra, do contratante ou proprietário"
        title="Nome da obra, do contratante ou proprietário"
        solo
        prepend-inner-icon="mdi-magnify"
        hide-details
        flat
      ></v-text-field>
    </v-col>
  </v-toolbar>
</template>

<script>
export default {
  components: {},
  props: {
    page: { type: Number, default: 1 }
  },

  data() {
    return {
      query: {}
    };
  },
  watch: {
    page(newValue, oldValue) {
      this.refresh();
    }
  },

  computed: {
    filterData() {
      return {
        page: this.page,
        ...this.query,
        roles: this.query.roles && this.query.roles.join()
      };
    }
  },
  methods: {
    async filter() {
      try {
        this.$loading.show();
        await this.$store.dispatch("constructions/refresh", {
          ...this.filterData,
          page: 1
        });
        this.$loading.hide();
      } catch (error) {
        this.$message.serverError(error);
        this.$loading.hide();
      }
    },
    /**Atualiza a lista */
    async refresh() {
      try {
        this.$loading.show();
        await this.$store.dispatch("constructions/refresh", this.filterData);
        this.$loading.hide();
      } catch (error) {
        this.$message.serverError(error);
        this.$loading.hide();
      }
    },

    /**
     * Ao limpar o campo de busca
     */
    onClear() {
      this.query.q = null;
      this.refresh();
    }
  }
};
</script>

<style>
</style>
