<template>
  <KPage>
    <v-toolbar flat>
      <v-btn icon @click="load">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </v-toolbar>

    <ServiceList
      ref="services_list"
      :status="$route.query.status"
      :next-days="$route.query.next_days"
      show-construction
    />
  </KPage>
</template>

<script>
import KPage from "@/components/KPage";

import ServiceList from "./List";

export default {
  components: {
    KPage,
    ServiceList
  },

  computed: {
    title() {
      if (this.$route.query.status && !this.$route.query.next_days) {
        switch (this.$route.query.status) {
          case "finished":
            return "Serviços Finalizados";
          case "canceled":
            return "Serviços Cancelados";
          case "in_progress":
            return "Serviços Em Andamento";
          case "reminder":
            return "Lembretes de Serviços";
          case "scheduled":
            return "Serviços Agendados";
        }
      }

      if (this.$route.query.status && this.$route.query.next_days) {
        const days = this.$route.query.next_days;
        switch (this.$route.query.status) {
          case "reminder":
            return "Lembretes para os próximos " + days + " dias";
          case "scheduled":
            return "Agendados para os próximos " + days + " dias";
        }
      }

      return "Serviços";
    }
  },

  methods: {
    load() {
      this.$refs.services_list.load();
    }
  },

  mounted() {
    this.$app.title(this.title);
  }
};
</script>

<style>
</style>
