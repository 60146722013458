<template>
  <v-row class="ma-0">
    <v-col cols="12" class="px-0">
      <v-card outlined :loading="loading" min-height="20">
        <!-- Se estiver carregando e não tiver nenhum item -->
        <v-card-text v-if="loading && !items.length">Carregando</v-card-text>

        <!-- Se não estiver e não tiver nenhum item  -->
        <v-card-text v-if="!loading && !items.length"
          >Nenhum serviço cadastrado</v-card-text
        >

        <!-- Se tiver itens -->
        <v-list class="py-0 bordered" v-if="items && items.length">
          <v-list-item
            v-for="item in items"
            :key="item.id"
            :to="`/constructions/${item.construction_id}/services/view/${item.id}`"
          >
            <v-list-item-content>
              <v-list-item-title v-if="showConstruction && item.construction">
                {{ item.construction.name }}
              </v-list-item-title>
              <v-list-item-title>
                <KChip :color="item.status.color" :icon="item.status.icon">{{
                  item.status.name
                }}</KChip>
                {{ item.description }}
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-icon color="primary">mdi-chevron-right</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card>

      <!-- Se não existem serviços -->
      <!-- <KAlert v-if="items && items.length === 0" info>
        Nenhum serviço cadastrado até o momento
      </KAlert> -->
    </v-col>
  </v-row>
</template>

<script>
import KChip from "@/components/KChip";
import KAlert from "@/components/KAlert";

export default {
  name: "services-list",

  components: {
    KChip,
    KAlert
  },

  props: {
    construction_id: [Number, String],
    status: String,
    // items: Array,
    showConstruction: Boolean,
    nextDays: [Number, String]
  },

  // watch: {
  // items() {
  // if (this.items) this.list = this.items;
  // }
  // },

  data() {
    return {
      items: [],
      loading: false
    };
  },

  computed: {
    payload() {
      // console.log("nextdays", this.nextDays);
      const payload = {
        showConstruction: true,
        showStatus: true
      };

      if (this.status) payload.status = this.status;
      if (this.construction_id) payload.construction_id = this.construction_id;
      if (this.nextDays) payload.next_days = this.nextDays;

      return payload;
    }
  },

  methods: {
    /**
     *
     */
    async load(payload = {}) {
      // console.log("carregar os itens do serviço");
      try {
        // if (this.construction_id) {
        this.loading = true;
        const { data } = await this.$api.get(`/constructions/services`, {
          ...this.payload,
          ...payload
        });

        // console.log({ data });
        this.loading = false;
        this.items = data.data;
        // }
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.$message.serverError(error);
      }
    }
  },

  mounted() {
    this.load();
  }
};
</script>

<style>
</style>
