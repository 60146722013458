<template>
  <v-card outlined class="mt-3">
    <!--  -->
    <v-row class="ma-0">
      <v-col
        class="pa-2"
        v-for="action in actions"
        :key="action.label"
        cols="12"
        sm="6"
        md="3"
      >
        <v-btn depressed :href="action.url" block rounded target="_blank">
          <v-icon left>{{ action.icon }}</v-icon>
          {{ action.label }}
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "report-buttons",

  props: {
    public_key: String,
    private_key: String
  },

  computed: {
    actions() {
      const base = process.env.VUE_APP_API_URL;
      return [
        {
          label: "qrcode público",
          icon: "mdi-qrcode-scan",
          url: `${base}/constructions/qrcode/${this.public_key}`
        },
        {
          label: "qrcode privado",
          icon: "mdi-qrcode-remove",
          url: `${base}/constructions/qrcode/${this.private_key}`
        },
        {
          label: "relatório público",
          icon: "mdi-table-eye",
          url: `${base}/constructions/report/${this.public_key}`
        },
        {
          label: "relatório privado",
          icon: "mdi-table-eye-off",
          url: `${base}/constructions/report/${this.private_key}`
        }
      ];
    }
  }
};
</script>

<style>
</style>
