<template>
  <k-page>
    <k-form ref="form" v-if="!loading">
      <v-container>
        <!-- <SectionHeader title="Dados da Obra" /> -->

        <!-- Dados da obra -->
        <v-row class="my-4" v-if="form.construction">
          <v-col cols="12" class="py-1">
            <SectionHeader title="Dados da Obra" />
          </v-col>
          <v-col cols="12">
            <v-card outlined>
              <v-card-title>{{ form.construction.name }}</v-card-title>
              <!-- <v-card-subtitle>
            {{ data.description }}
          </v-card-subtitle> -->
            </v-card>
          </v-col>
        </v-row>

        <SectionHeader title="Dados do Serviço" />

        <v-row class="my-4">
          <!-- Nome da Obra -->
          <v-col cols="12" class="py-1">
            <v-text-field
              dense
              outlined
              :rules="[$validation.isRequired]"
              :error-messages="errors.description"
              label="Descrição do serviço"
              hint="Informe um texto descritivo para este serviço"
              persistent-hint
              v-model="form.description"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="4" class="py-1">
            <v-text-field
              dense
              outlined
              :rules="[$validation.isRequired]"
              :error-messages="errors.art"
              label="ART"
              hint="Número da notação de responsabilidade técnica"
              persistent-hint
              v-model="form.art"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="4" class="py-1">
            <KInputDate
              dense
              outlined
              :rules="[$validation.isRequired]"
              :error-messages="errors.start_date"
              label="Data de início do serviço"
              v-model="form.start_date"
              required
            ></KInputDate>
          </v-col>

          <v-col cols="12" sm="4" class="py-1">
            <KInputDate
              dense
              outlined
              :error-messages="errors.finish_date"
              label="Data de finalização do serviço"
              persistent-hint
              v-model="form.finish_date"
              required
            ></KInputDate>
          </v-col>
        </v-row>

        <SectionHeader
          title="Responsáveis técnicos"
          :actions="[
            {
              to: `/people/add`,
              target: '_blank',
              icon: 'mdi-plus',
              label: 'Cadastrar nova pessoa',
            },
          ]"
        />

        <v-row class="mb-4">
          <!-- Contratante -->
          <v-col cols="12" md="6" class="py-md-0">
            <PersonInfoSelect
              dense
              outlined
              label="Responsável técnico pela execução"
              :person="form.execution_technical"
              :error-messages="errors.execution_technical_id"
              :search-params="{ showRoles: '' }"
              v-model="form.execution_technical_id"
              @select="onSelectContractor"
              @clear="form.contractor = {}"
            />
          </v-col>
          <!-- Contratante -->
          <v-col cols="12" md="6" class="py-md-0">
            <PersonInfoSelect
              dense
              outlined
              label="Responsável técnico pela fiscalização"
              :person="form.owner_technical"
              :error-messages="errors.owner_technical_id"
              :search-params="{ showRoles: '' }"
              v-model="form.owner_technical_id"
              @select="onSelectContractor"
              @clear="form.contractor = {}"
            />
          </v-col>
        </v-row>

        <SectionHeader title="Status do Serviço" />

        <v-row class="my-4">
          <!-- Contratante -->
          <v-col cols="12" class="py-0">
            <v-select
              dense
              outlined
              :rules="[$validation.isRequired]"
              :error-messages="errors.construction_service_status_id"
              :items="statuses"
              item-value="id"
              item-text="name"
              label="Status do serviço"
              hint="Selecione o status atual deste serviço"
              persistent-hint
              v-model="form.construction_service_status_id"
              required
            ></v-select>
          </v-col>
        </v-row>

        <!-- 

              Edição

           -->
        <template v-if="isEditing">
          <!-- Grupos -->
          <SectionHeader
            title="Grupos do Serviço"
            subtitle="Agrupamentos de arquivos e informações relacionadas ao serviço"
            :actions="[
              {
                to: '/grouping',
                icon: 'mdi-plus',
                label: 'Cadastrar novo grupo',
              },
              {
                icon: 'mdi-refresh',
                eventName: 'click:refresh',
                label: 'Atualizar a lista de grupos',
              },
            ]"
            @click:refresh="handleRefreshGroupClick"
          />

          <GroupsList
            reference_module="service"
            :reference_module_id="form.id"
            ref="groups-list"
          />
        </template>

        <!-- Botão de salvar -->
        <v-row class="ma-0 mb-8" align="center" justify="center">
          <v-btn color="accent" elevation="0" rounded @click="save"
            >Salvar</v-btn
          >
        </v-row>
      </v-container>
    </k-form>
  </k-page>
</template>

<script>
import KPage from "@/components/KPage";
import KForm from "@/components/KForm";
import SectionHeader from "@/components/SectionHeader";
import KInputDate from "@/components/KInput/Date";

import GroupsList from "@/views/Grouping/Children/List";
import PersonInfoSelect from "@/components/PersonInfoSelect";

export default {
  name: "page-save",

  components: {
    KPage,
    KForm,
    GroupsList,
    KInputDate,
    SectionHeader,
    PersonInfoSelect
  },

  // directives: { mask },

  data() {
    return {
      form: {},
      errors: [],
      addresses: [],
      loading: false
    };
  },

  computed: {
    isEditing() {
      return !!this.$route.params.id;
    },
    construction_id() {
      return this.$route.params.construction_id;
    },
    service_id() {
      return this.$route.params.id;
    },
    statuses() {
      return this.$store.getters["construction_statuses/all"];
    }
  },

  methods: {
    /**
     *
     *
     */
    async save() {
      try {
        this.$loading.show();
        this.errors = {};
        //
        const payload = {
          execution_technical_id: this.form.execution_technical_id,
          owner_technical_id: this.form.owner_technical_id,
          construction_service_status_id: this.form
            .construction_service_status_id,
          description: this.form.description,
          art: this.form.art,
          start_date: this.form.start_date,
          finish_date: this.form.finish_date
        };

        let service = {};

        if (this.isEditing) {
          ({
            data: { data: service }
          } = await this.$api.put(
            `/constructions/${this.construction_id}/services/${this.service_id}`,
            payload
          ));
        } else {
          ({
            data: { data: service }
          } = await this.$api.post(
            `/constructions/${this.construction_id}/services`,
            payload
          ));
        }

        // console.log("service", service);

        this.$loading.hide();
        this.$message.success("Registro salvo com sucesso");
        this.$router.push(
          `/constructions/${this.construction_id}/services/view/${service.id}`
        );

        // console.log(payload);
      } catch (error) {
        this.$loading.hide();
        this.errors = this.$message.serverError(error);
        // console.log("erro ao salvar", errors);
        // console.log(errors);
      }
    },

    /**
     *
     *
     */
    onSelectOwner(person) {
      const { addresses, ...owner } = person;
      // console.log("onSelectOwner", owner);

      // console.log(addresses);
      this.addresses = addresses;

      this.form.owner = owner;
      // this.form.owner_id = owner.id;
    },

    /**
     *
     *
     */
    onClearOwner() {
      this.form.address_id = null;
      this.form.owner = {};
    },

    /**
     *
     *
     */
    onSelectContractor(person) {
      // console.log("selecionou", person);
      // const { addresses, ...contractor } = person;
      // this.form.contractor = contractor;
    },

    /**
     *
     *
     */
    async load() {
      this.$loading.show();
      this.loading = true;

      const { data } = await this.$api.get(
        `/constructions/${this.construction_id}/services/${this.service_id}`
      );

      // console.log(data.data);
      this.form = data.data;
      // this.addresses = data.data.owner.addresses;
      // this.onSelectOwner(data.data.owner);

      this.$app.title(`Serviço ${data.data.description}`);

      this.$loading.hide();
      this.loading = false;
    },

    handleRefreshGroupClick() {
      // console.log("clicou em atualizar os grupos");
      this.$refs["groups-list"].loadItems();
    },

    handleRefreshServiceClick() {
      this.$refs["services-list"].loadItems();
    }
  },

  async mounted() {
    if (this.isEditing) {
      await this.load();
      // this.loadAddressByUser(this.form.owner_id);
    }

    this.$store.dispatch("construction_statuses/all");
  }
};
</script>

<style>
</style>
